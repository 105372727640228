<template>
    <div class="main-starting">
        <div class="starting-back-2" :style="{ backgroundImage: `url(${backgroundImageUrl})` }">
            <div class="startings-details">
                <h1>{{ headingText }}</h1>
                <div class="starting-details-2">
                <!-- <p>
                    <router-link to="/">{{ homeLinkText }}</router-link> > 
                    <router-link :to="aboutLink">{{ aboutLinkText }}</router-link>
                </p> -->
            </div>
            </div>
            <!-- <div class="svg-sect">
                <div class="l-section-shape type_wave pos_bottom" style="height:5rem;color:#d6e0d5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 4 64 4" preserveAspectRatio="none" width="100%" height="100%">
                        <path fill="currentColor" d="M64 6 C32 0 32 12 0 6 L0 8 L64 8 Z"></path>
                    </svg>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name:"StartingSection2",
    props: {
        backgroundImageUrl: {
            type: String,
            default: () => require('@/assets/images/sect-1.webp')
        },
        headingText: {
            type: String,
            default: 'Where people really count'
        },
        homeLinkText: {
            type: String,
            default: 'Home'
        },
        aboutLinkText: {
            type: String,
            default: 'About Us'
        },
        aboutLink: {
            type: String,
            default: '/about'
        }
    }
}
</script>


<style>
.starting-back-2 {
  position: relative;
  padding-top: 135px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 108px;
  z-index: 1; /* Ensure content stays above the pseudo-elements */
}

.starting-back-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: 108px;
  /* background-image: url('@/assets/images/back.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.7) grayscale(0.4); /* Dulls the image */
  z-index: -1; /* Keeps the dull background behind the content */
  opacity: 1; /* Adjust if needed */
}

.starting-back-2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(47, 77, 209, 0.507); /* Adjust the opacity for darkness */
  border-bottom-left-radius: 108px; /* Match the border radius */
  z-index: -1; /* Keep the overlay behind the content */
}


.starting-details-2 {
    padding: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
}

@media(max-width:650px) {
    .starting-details-2 {
        padding: 15px;
        width: auto;
    }
}

.startings-details h1 {
    font-size: 80px;
    color: var(--white-color)!important;
    padding-top: 33px;
    text-align: center;
}

.starting-details-2 a{
    color: var(--white-color) !important;
    text-align: left;
}
.starting-details-2 p{
    color: var(--white-color) !important;
    /* padding-left: 100px; */
    text-align: center;
}
@media(max-width:600px) {
    .starting-details-2 p{
    padding-left: 15px;

    }
}
@media(max-width:800px) {
    .startings-details h1 {
        font-size: 50px;
    }
}
@media(max-width:450px) {
    .startings-details h1 {
        font-size: 32px;
    }
}

.main-starting {
    margin-top: -41px;
}
</style>