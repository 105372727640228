<template>
  <!-- Sexy Loader Component -->
  <LoaderComponent :isLoading="isLoading" />

  <!-- Navbar and Sidebar -->
  <NavbarComponent @toggle-sidebar="toggleSidebar" />
  <SidebarComponent :isOpen="isSidebarOpen" @close-sidebar="toggleSidebar" />

  <!-- Main Content -->
  <router-view v-if="!isLoading" />

  <!-- Scroll to Top Button -->
  <button 
    v-if="isScrolled"
    class="scroll-to-top"
    @click="scrollToTop"
  >
    ⬆
  </button>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';
import SidebarComponent from './components/SidebarComponent.vue';
import LoaderComponent from './components/LoaderComponent.vue';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    LoaderComponent,
  },
  data() {
    return {
      isSidebarOpen: false,
      isScrolled: false, // For showing button on scroll
      isLoading: true, // Controls the loader visibility
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 100; // Show after 100px scroll
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scroll to top
      });
    },
    startLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false; // Set to false after 2 seconds
      }, 1300); // Loader runs for 2 seconds
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.startLoading(); // Trigger loader on initial mount
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  
  watch: {
    $route() {
      this.startLoading(); // Trigger loader on route change
    }
  }
};
</script>

<style scoped>
/* Scroll to Top Button */
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: #0CB5D5; /* Starting light theme color */
  border: none;
  border-radius: 50%;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 28px;
  cursor: pointer;
  transition: transform 0.5s ease, background-color 1s ease, box-shadow 0.5s ease;
  animation: pulse 2s infinite, spin 3s infinite ease-in-out;
  z-index: 1000;
}

/* Hover Effects: Light Pulse & Spin */
.scroll-to-top:hover {
  background-color: #0CB5D5; /* Darker on hover */
  box-shadow: 0 6px 30px rgba(0, 198, 233, 0.7), 0 0 30px rgba(255, 255, 255, 0.7); /* Glow */
  animation: spin 1s infinite linear, wave 0.8s infinite ease-in-out;
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* 3D Spin Animation */
@keyframes spin {
  0%, 100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}

/* Wave Effect Animation */
@keyframes wave {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  25% {
    transform: translateX(-3px) rotate(-5deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(3px) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Loader styles and your custom font */
@font-face {
  font-family: 'PlusJakartaSans-Bold';
  src: url('@/assets/fonts/static/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DMSerifText-Regular';
  src: url('@/assets/fonts/static/DMSerifText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Light';
  src: url('@/assets/fonts/static/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Example of usage */
h1 {
  font-family: 'DMSerifText-Regular', serif;
}

p {
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  font-weight: bold;
}


html, body {
  font-weight: bold;
}

#app {
  font-family: 'DMSerifText-Regular' ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
