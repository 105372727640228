<template>
  <div class="loader-container" v-if="isLoading">
    <div class="text-wrapper">
      <span 
        v-for="(char, index) in text.split('')" 
        :key="index" 
        :class="{ 'space': char === ' ' }"
        :style="{ animationDelay: `${index * 0.06}s` }" 
      >
        {{ char }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: 'CFO ON CALL', // Text to display in loader
    };
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.hideLoaderAfterAnimation();
      }
    },
  },
  methods: {
    hideLoaderAfterAnimation() {
      const delayPerCharacter = 0.06; // Matches the delay in the span
      const totalCharacters = this.text.length; // Total characters in the text
      const lastCharacterDelay = totalCharacters * delayPerCharacter; // Time delay for the last character
      const animationDuration = 1; // Duration for each character's fade-in animation

      const totalTime = lastCharacterDelay + animationDuration; // Total time for the entire animation

      setTimeout(() => {
        this.$emit('update:isLoading', false); // Hide loader after the last animation completes
      }, totalTime * 1000); // Convert to milliseconds
    },
  },
  mounted() {
    if (this.isLoading) {
      this.hideLoaderAfterAnimation();
    }
  },
};
</script>

<style scoped>
/* Full-screen loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d0d0d; /* Dark background */
  z-index: 9999;
}

/* Text wrapper styling */
.text-wrapper {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-family: 'DMSerifText-Regular', sans-serif;
  color: #0cb5d5; /* Bright color for text */
  font-weight: bold;
  letter-spacing: 0.1em;
}

/* Individual character styling */
.text-wrapper span {
  display: inline-block;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 1s forwards;
  /* Matches the animationDelay in the template */
}

/* Special class for spaces */
.text-wrapper .space {
  width: 0.5em; /* Adjust the width to control the space */
}

/* Animation effect */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
